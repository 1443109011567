import React from 'react'

//Import images
import FaqImg from '../../assets/haufige-fragen-amd.jpg'

function FaqHero() {
    return (
        <div className="faq-hero-container">
            <div className="faq-hero">
                <img src={FaqImg} alt="Häufige Fragen" className="faq-hero-img" />
                <h1 className="faq-hero-title">Fragen & Antworten</h1>
            </div>
            
        </div>
    )
}

export default FaqHero
