import * as React from "react"


const faqList = [
    {
        id: 1,
        Title: 'Ersetzen unsere Produkte eine ausgewogene Ernährung?',
        Desc: 'Die Produkte der Augenpermanence sind mit den hochwertigsten Inhaltstoffen konzipiert. Sie sind dafür gedacht, ergänzend Ihren Lebensstil zu unterstützen.'
    },
    {
        id: 2,
        Title: 'Müssen die Produkte im Kühlschrank gelagert werden?',
        Desc: 'Unsere Produkte kommen in einer lichtgeschützten Verpackung und sollten lediglich an einem kühlen Ort, ohne direkte Sonnenstrahlung gelagert werden. Da eine Verschluckgefahr durch Kinder besteht, sollten diese ausserhalb der Reichweite von Kindern aufbewahrt werden.'
    },
    {
        id: 3,
        Title: 'Ist Makula geeignet für Veganer / Vegetarier?',
        Desc: 'Wichtige Inhaltsstoffe müssten von Makula entfernt werden, damit es geeignet für Veganer / Vegetarier wäre. Wir sind bestrebt eine Version für Veganer und Vegetarier zu entwickeln. Eine Vegane Formel wird ca. ab Januar 2022 verfügbar sein.'
    },
    {
        id: 4,
        Title: 'Ist Makula geeignet für schwangere / stillende Frauen?',
        Desc: 'Unsere Produkte wurden nicht für schwangere oder stillende Frauen entwickelt . Wir bitten Sie sich diesbezüglich mit Ihrem Arzt oder Ärztin in Verbindung zu setzen.'
    },
    {
        id: 5,
        Title: 'Was ist, wenn ich vergesse eine Dosis zu nehmen?',
        Desc: 'Dies ist kein Problem. Wenn Sie vergessen sollten, eine Dosis einzunehmen, bitten wir Sie nicht die Menge zu verdoppeln, da die Inhaltsstoffe für einen ganzen Tag ausgelegt sind.'
    },
    {
        id: 6,
        Title: 'Kann ich Makula mit anderen Medikamenten oder Nahrungsergänzungsmitteln einnehmen?',
        Desc: 'Aufgrund der Vielzahl von Medikamenten können wir leider nicht sagen, ob in Ihrem Fall eine Kombination in Frage kommt. Wir bitten Sie, dies mit Ihrem Arzt oder Ärztin anzuschauen.'
    },
    
]

function FaqList() {
    return (
        <div className="faq-list-container">
            {faqList.map((faqItem) => (
                <div className="faq-list" key={faqItem.id}><h2>{faqItem.Title}</h2><h3>{faqItem.Desc}</h3></div>
            ))}
        </div>
    )
}

export default FaqList
