import React from 'react'

//Import Components
import Layout from '../components/layout'
import Seo from "../components/seo"
import FaqHero from '../components/FAQ/FaqHero'
import FaqList from '../components/FAQ/FaqList'

//Import CSS
import '../styles/App.scss'

function Faq() {
    return (
        <>
        <Layout>
            <Seo 
            title="Häufige Fragen" 
            description="Hier beantworten wir Fragen, welche oft von unseren Kunden gestellt worden sind. Haben Sie eine andere Frage? Kontaktieren Sie uns."
            />    
                <FaqHero />
                <FaqList />
        </Layout>   
        </>
    )
}

export default Faq
